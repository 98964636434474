import { createContext, useContext, useMemo, useState } from "react";
import type { ChordSelectorStateProps } from "../_ChordSelectors/ChordSelector/util";
import type { ChordFunctionSelectorStateProps } from "../_ChordSelectors/ChordFunctionSelector/useProps";
import type { SearchTypeSelectorProps } from "./ChordProgressionSearchBar/SearchTypeSelector";

export type SiteHeaderInitialValues = {
  searchType: SearchTypeSelectorProps["searchType"];
  nashvilleChordSelectorStateList: ChordSelectorStateProps[];
  degreeChordSelectorStateList: ChordSelectorStateProps[];
  chordFunctionSelectorStateList: ChordFunctionSelectorStateProps[];
};

type ChordProgressionSearchBarContextType = {
  siteHeaderInitialValues: SiteHeaderInitialValues;
  setSiteHeaderInitialValues: React.Dispatch<
    React.SetStateAction<SiteHeaderInitialValues>
  >;
};

const ChordProgressionSearchBarContext =
  createContext<ChordProgressionSearchBarContextType>(
    // @ts-expect-error 必ず値を入れるのでここでは無視
    {},
  );

export const useChordProgressionSearchBarContext =
  (): ChordProgressionSearchBarContextType =>
    useContext(ChordProgressionSearchBarContext);

export function ChordProgressionSearchBarProvider({
  children,
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  const [siteHeaderInitialValues, setSiteHeaderInitialValues] =
    // TODO: ここクエリパラメータから取得するのでは？うーんでもこんなとこでクエリパラメータ見たくないな…なんでcontextにしたんだっけ？不要では？いやこれやらないとあれなんだ、ページまたいだ時にうまくいかなかったんだな、、、となるとやっぱりこれの初期値を、ページ変わるたびにクエリパラメータから取得する感じかな…？うーん
    useState<SiteHeaderInitialValues>({
      searchType: "free_input",
      nashvilleChordSelectorStateList: [],
      degreeChordSelectorStateList: [],
      chordFunctionSelectorStateList: [],
    });

  const values = useMemo(() => {
    return {
      siteHeaderInitialValues,
      setSiteHeaderInitialValues,
    };
  }, [siteHeaderInitialValues, setSiteHeaderInitialValues]);

  return (
    <ChordProgressionSearchBarContext.Provider value={values}>
      {children}
    </ChordProgressionSearchBarContext.Provider>
  );
}
